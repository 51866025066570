<template>
  <div class="loginLogList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-input label="用户名" v-model="searchParams.userName"></v-input>
        <v-input label="登录名" v-model="searchParams.loginName"></v-input>
        <v-input label="手机号码" v-model="searchParams.mobileNum"></v-input>
        <v-datepicker
          label="登录时间段"
          type="rangedatetimer"
          defaultTimeType="week"
          :startTime.sync="searchParams.regFromDate"
          :endTime.sync="searchParams.regToDate"
        ></v-datepicker>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL } from './api'

export default {
  name: 'LoginLogList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      searchParams: {
        userName: '',
        loginName: '',
        mobileNum: '',
        regFromDate: '',
        regToDate: ''
      },
      headers: [
        {
          prop: 'userName',
          label: '用户名'
        },
        {
          prop: 'loginName',
          label: '登录名'
        },
        {
          prop: 'mobileNum',
          label: '手机号码'
        },
        {
          prop: 'userTypeName',
          label: '用户类别'
        },
        {
          prop: 'ipAddress',
          label: 'IP地址'
        },
        {
          prop: 'intime',
          label: '登录时间'
        }
      ]
    }
  }
}
</script>
